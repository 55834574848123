define("discourse/plugins/discourse-whos-online/discourse/templates/components/whos-online", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div id="whos-online" class={{if this.isLong "collapsed"}}>
      <span
        title={{i18n
          "whos_online.tooltip"
          count=this.siteSettings.whos_online_active_timeago
        }}
      >
        {{#if this.hasUsers}}
          {{i18n "whos_online.title" count=this.count}}
        {{else if this.hasCount}}
          {{i18n "whos_online.count_only" count=this.count}}
        {{else}}
          {{i18n "whos_online.no_users"}}
        {{/if}}
      </span>
      {{#if this.hasUsers}}
        {{#each this.users as |user|}}
          {{whos-online-avatar user=user}}
        {{/each}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "89engIh8",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,1,\"whos-online\"],[15,0,[52,[30,0,[\"isLong\"]],\"collapsed\"]],[12],[1,\"\\n    \"],[10,1],[15,\"title\",[28,[37,3],[\"whos_online.tooltip\"],[[\"count\"],[[30,0,[\"siteSettings\",\"whos_online_active_timeago\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasUsers\"]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.title\"],[[\"count\"],[[30,0,[\"count\"]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasCount\"]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.count_only\"],[[\"count\"],[[30,0,[\"count\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.no_users\"],null]],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasUsers\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,6],null,[[\"user\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"div\",\"span\",\"i18n\",\"each\",\"-track-array\",\"whos-online-avatar\"]]",
    "moduleName": "discourse/plugins/discourse-whos-online/discourse/templates/components/whos-online.hbs",
    "isStrictMode": false
  });
});